import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

export const Medicine = () => {

    return (

        <>
            <Helmet>
				<title>Kutaisi University at Faculty of Medicine | Georgia</title>
				<meta name="description" content="Kutaisi University Faculty of Medicine offers a comprehensive medical education with hands-on experience from experienced faculty in a top-notch learning environment. Discover your medical education today at Kutaisi University!" />
				<meta name="keywords" content="Kutaisi University Medical Program, Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students , Faculty Of Medicine" />
				<link rel="canonical" href="https://europeanuniversity.in/faculty-of-medicine" />
			</Helmet>

            <div className="rs-breadcrumbs breadcrumbs-overlay">
                <div className="breadcrumbs-img">
                    <img src="banner/page.jpg" alt="Breadcrumbs Image" className="custom_height" />
                </div>
                <div className="breadcrumbs-text white-color">
                    <h1 className="page-title">Faculties</h1>
                    <ul>
                        <li>
                            <a className="active" href="/">Home</a>
                        </li>
                        <li>Faculty of Medicine</li>
                    </ul>
                </div>
            </div>
            {/* Categories Section Start */}
            <div id="rs-categories" className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-40">
                <div className="container">
                    {/* <div className="sec-title3 text-center mb-45">
                        <div className="sub-title"> Top Categories</div>
                        <h2 className="title black-color">Popular Online Categories</h2>
                    </div> */}
                    <div className="row mb-35">
                        <div className="entry-content clear" itemProp="text">
                            <h3>Faculty of Medicine Kutaisi University</h3>
                            <p>
                                The faculty aims to train competent, patient-oriented physicians who will be
                                equipped with modern international standard-level theoretical knowledge and
                                clinical/research skills; Doctors who will be actively involved in the
                                lifelong learning and teaching process and will contribute to the well-being
                                of the community.
                            </p>
                            <p>
                                The program is accredited by the legislation of Georgia; it complies with
                                modern international standards and is based on World Federation of Medical
                                Education (WFME) standards.
                            </p>
                            <p>
                                Kutaisi University is a member of the European Medical Education Association
                                (AMEE). The Association is the world’s leading medical education
                                organization with members in 90 countries which will enable the staff and
                                the students to be more actively involved in international conferences,
                                seminars, and activities related to the teaching of medicine.
                            </p>
                            <p>
                                The university has a clinical skill-enhancing center that allows students
                                through moulages and mannequins learn and put into practice all the
                                manipulations they will need for professional activities. The equipment at
                                the center meets international standards and is gradually upgraded.
                            </p>
                            <p>
                                The university has biochemical and histological, Physiological, and
                                Microbiological/Immunological laboratories.
                            </p>
                            <p>
                                Currently, is implementing the Medical Doctor (MD) Program at the Faculty of
                                Medicine.
                            </p>
                            <p>
                                Qualification: Medical Doctor (MD)
                                <br />
                                Program volume in credits: 360 ECTS
                                <br />
                                The Fee of Programme: 4000 U.S Dollar
                                <br />
                                Programme Status: Accredited
                                <br />
                                Language of Instruction: English
                            </p>
                            <p>
                                Welcome to the Kutaisi University at Faculty of Medicine – an innovative
                                institution where patient-driven education and cutting-edge research work to
                                advance health care within our city, across the country, and throughout the
                                world. Our faculty members are leaders in the field of medical and health
                                education, working with both national health education organizations and the
                                government to establish standardized learning techniques and develop
                                national policies. Our ideals, partnerships, and unique opportunities enable
                                each of us to live up to the mission of the faculty at Kutaisi University.
                                We look forward to supporting the personal and professional growth of our
                                medical students and faculty members. Medicine is a life of service and at
                                times sacrifice. It is a life full of rich rewards because of the profound
                                and direct difference we can make in people’s lives and I am pleased that
                                you are pursuing medicine as a career. The challenges and rewards of medical
                                school, culminating in the gratification of practicing in one’s chosen
                                specialty and making a positive impact on society, will be one of your most
                                life-changing experiences. Our dedicated faculty will help you acquire the
                                knowledge and proficiency you will need. Our affiliated hospitals will offer
                                you a variety and depth of clinical experiences that is unrivaled and will
                                be cited by our graduates as proof of our exceptional quality as a medical
                                school.
                            </p>
                            <p>
                                The Faculty of Medicine at the University of Kutaisi offers one of the
                                nation’s best graduate programs. To implement the educational program and
                                achieve the learning outcomes. provided by the program, the University has
                                the appropriate material and technical bases, like the study auditoriums, a
                                library with mandatory and additional book funds, electronic resources and
                                international electronic databases, a biochemical and histological
                                Laboratory, a 3D Anatomical Theater, simulation laboratories with mannequins
                                provided with training components, examination center, working space for
                                academic staff and so on.
                            </p>
                            <p>
                                We are constantly surveying the medical education landscape to find ways to
                                improve and prepare our students for the myriad of complex health issues
                                that they will one day face with their patients. Medicine needs the next
                                generation of physicians to be people who both excel and lead in the
                                clinical setting as innovators of new processes and technologies designed to
                                support and enhance clinical care. While the focus of your time here is
                                primarily academic, I also encourage you to participate fully in the
                                extracurricular life, to devote yourself to service to the community, and to
                                invest some of your time and energy in building the relationships that will
                                enrich you for a lifetime.
                            </p>
                            <p />
                            </div>
 
                    </div>
                    
                </div>
            </div>
            {/* Categories Section End */}
        </>

    )
}