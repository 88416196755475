import {BlogList} from '../components/Blog'
export const Blog = () => {
	return(
		<>
			<div className="rs-breadcrumbs breadcrumbs-overlay">
		        <div className="breadcrumbs-img">
		          <img src="banner/page.jpg" alt="Breadcrumbs Image" />
		        </div>
		        <div className="breadcrumbs-text white-color">
		          <h1 className="page-title">Blogs</h1>
		          <ul>
		            <li>
		              <a className="active" href="/">Home</a>
		            </li>
		            <li>Blogs</li>
		          </ul>
		        </div>
		      </div>

		      <BlogList />
		</>
	)
}
