import React, { useEffect, useState } from 'react'
import { celebration, classroom, hostal } from '../utils/gallery';
import { PUBLIC_URL } from '../utils/constant';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// let a = []

// const images = require.context('../../public/common_images', true);
// const imageList = images.keys().map(image => a.push(image));

// console.log(a)

export const Gallery = () => {

  const [classroomSize, setClassRoomSize] = useState(6)
  const [celebrationSize, setCelebrationSize] = useState(6)
  const [hostelSize, setHostelSize] = useState(6)
  const location = useLocation()
  useEffect(() => {
    var imagepopup = window.$('.image-popup');
    if (imagepopup.length) {
      window.$('.image-popup').magnificPopup({
        type: 'image',
        callbacks: {
          beforeOpen: function () {
            this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
          }
        },
        gallery: {
          enabled: true
        }
      });
    }
  }, [location?.pathname, classroomSize, celebrationSize, hostelSize])

  function loadMoreClassroom() {
		if (classroom.length > classroomSize + 6) {
			setClassRoomSize(classroomSize + 6)
		} else {
			setClassRoomSize(classroomSize + (classroom.length - classroomSize))
		}
	}

  function loadMoreCelebration() {
		if (celebration.length > celebrationSize + 6) {
			setCelebrationSize(celebrationSize + 6)
		} else {
			setCelebrationSize(celebrationSize + (celebration.length - celebrationSize))
		}
	}

  function loadMoreHostel() {
		if (hostal.length > hostelSize + 6) {
			setHostelSize(hostelSize + 6)
		} else {
			setHostelSize(hostelSize + (hostal.length - hostelSize))
		}
	}

  return (
    <>
      <Helmet>
				<title>Study MBBS in Georgia | Explore Kutaisi University Gallery</title>
				<meta name="description" content="why studying MBBS in Georgia is a great option for international students. Take a virtual tour of the Kutaisi University Gallery and learn more about its world-class medical education programs. Start your journey to becoming a doctor today." />
				<meta name="keywords" content="Kutaisi University Imgaes , Kutaisi University  Videos , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://europeanuniversity.in/gallery" />
			</Helmet>

      <div className="rs-breadcrumbs breadcrumbs-overlay">
        <div className="breadcrumbs-img">
          <img src="/banner/page.jpg" alt="Breadcrumbs Image" />
        </div>
        <div className="breadcrumbs-text white-color">
          <h1 className="page-title">Gallery</h1>
          <ul>
            <li>
              <a className="active" href="/">Home</a>
            </li>
            <li>Gallery</li>
          </ul>
        </div>
      </div>


      <div className="rs-gallery pt-100 pb-100 md-pt-70 md-pb-70">
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h3>Classrooms and Infrastructure</h3>
            </div>
            
            {classroom.slice(0, classroomSize).map((item,index)=>
              <div className="col-lg-4 mb-30 col-md-6" key={index}>
                <div className="gallery-img">
                  <a className="image-popup" href={`${PUBLIC_URL}/classrooms_infrastructure/${item}`}><img src={`${PUBLIC_URL}/classrooms_infrastructure/${item}`} alt={item} /></a>
                </div>
              </div>
            )}

            <div className='col-md-12 d-flex justify-content-center'>
              <button className='btn apply-btn text-white' onClick={()=>loadMoreClassroom()}>Load More</button>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12">
              <h3>Celebration</h3>
            </div>
            
            {celebration.slice(0, celebrationSize).map((item,index)=>
              <div className="col-lg-4 mb-30 col-md-6" key={index}>
                <div className="gallery-img">
                  <a className="image-popup" href={`${PUBLIC_URL}/celebration/${item}`}><img src={`${PUBLIC_URL}/celebration/${item}`} alt={item} /></a>
                </div>
              </div>
            )}

            <div className='col-md-12 d-flex justify-content-center'>
              <button className='btn apply-btn text-white' onClick={()=>loadMoreCelebration()}>Load More</button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h3>Hostel</h3>
            </div>
            
            {hostal.slice(0, hostelSize).map((item,index)=>
              <div className="col-lg-4 mb-30 col-md-6" key={index}>
                <div className="gallery-img">
                  <a className="image-popup" href={`${PUBLIC_URL}/hostel/${item}`}><img src={`${PUBLIC_URL}/hostel/${item}`} alt={item} /></a>
                </div>
              </div>
            )}

            <div className='col-md-12 d-flex justify-content-center'>
              <button className='btn apply-btn text-white' onClick={()=>loadMoreHostel()}>Load More</button>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}