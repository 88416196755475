import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';

export const Nav = () => {
	useEffect(()=>{
		 new window.WOW().init();

		var header = window.$('.menu-sticky');
	    var win = window.$(window);

	    win.on('scroll', function() {
	       var scroll = win.scrollTop();
	       if (scroll < 1) {
	           header.removeClass("sticky");
	       } else {
	           header.addClass("sticky");
	       }

	        window.$("section").each(function() {
	        var elementTop = window.$(this).offset().top - window.$('#rs-header').outerHeight();
	            if(scroll >= elementTop) {
	                window.$(this).addClass('loaded');
	            }
	        });

	    });


	    var navclose = window.$('#onepage-menu');
		   if(navclose.length){
		       window.$(".nav-menu li a").on("click", function () {
		           if (window.$(".showhide").is(":visible")) {
		               window.$(".showhide").trigger("click");
		           }
		       });
		       
		       if (window.$.fn.onePageNav) {
		           window.$(".nav-menu").onePageNav({
		               currentClass: "active-menu"
		           });
		       }
		   }

		   var navMain = window.$(".navbar-collapse");
		     navMain.on("click", "a:not([data-toggle])", null, function () {
		         navMain.collapse('hide');
		     }); 


		     //canvas menu
		    var navexpander = window.$('#nav-expander');
		    if(navexpander.length){
		        window.$('#nav-expander').on('click',function(e){
		            e.preventDefault();
		            window.$('body').toggleClass('nav-expanded');
		        });
		    }
		    var navclose = window.$('#nav-close');
		    if(navclose.length){
		        window.$('#nav-close').on('click',function(e){
		            e.preventDefault();
		            window.$('body').removeClass('nav-expanded');
		        });
		    }


		    window.$('.cat-menu-inner').hide();
			    window.$('.cat-btn').on('click',function(){
			        window.$('.cat-menu-inner').slideToggle();
			    })
	},[])

	return(
		<>
			<header id="rs-header" className="rs-header">
        {/* Topbar Area Start */}
        <div className="topbar-area">
          <div className="container">
            <div className="row y-middle">
              <div className="col-md-7">
                <ul className="topbar-contact">
                  <li>
                    <i className="flaticon-email" />
                    <a href="mailto:admission@kutaisiuniversity.com">admission@kutaisiuniversity.com</a>
                  </li>
                  <li>
                    <i className="flaticon-call" />
                    <a href="tel:+91 8939211180">+91 9962705000</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 text-right">
                <ul className="topbar-right">
                
                  <li className="btn-part">
                    <a className="apply-btn" href="#">Apply Now</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Topbar Area End */}
        {/* Menu Start */}
        <div className="menu-area menu-sticky">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-5">
                <div className="logo-cat-wrap">
                  <div className="logo-part pr-90">
                    <a className="dark-logo" href="/">
                      <img src={process.env.PUBLIC_URL+'/logo.png'} alt="" />
                    </a>
                    <a className="light-logo" href="/">
                      <img src={process.env.PUBLIC_URL+'/logo.png'} alt="" />
                    </a>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-7 text-center">
                <div className="rs-menu-area">
                  <div className="main-menu pr-90">
                    <div className="mobile-menu">
                      <a className="rs-menu-toggle">
                        <i className="fa fa-bars" />
                      </a>
                    </div>
                    <nav className="rs-menu">
                      <ul className="nav-menu">
                        <li className="menu-item"> <a href="/">Home</a>
                        </li>
                        <li className="menu-item">
                          <Link to="/about-us">About</Link>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#">Programs</a>
                          <ul className="sub-menu">
                            <li><Link to="/bachelor-program">Bachelor Program</Link> </li>
                            <li><Link to="/master-program">Master Program</Link> </li>
                            <li><Link to="/doctorate-program">Doctorate Program</Link> </li>
                            <li><Link to="/medical-program">Medical Program</Link></li> 
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#">Faculties</a>
                          <ul className="sub-menu">
                            <li><Link to="/faculty-of-medicine">Faculty of Medicine</Link> </li>
                            <li><Link to="/faculty-of-social-sciences-business-and-economics">Faculty of Social Sciences, Business and Law</Link> </li>
                          </ul>
                        </li>
                        <li className="menu-item">
                          <a href="/gallery">Gallery</a>
                        </li>
                        {/* <li className="menu-item">
                          <a href="/blog">Blog</a>
                        </li> */}
                        <li className="menu-item">
                          <a href="/contact-us">Contact</a>
                        </li>
                      </ul> {/* //.nav-menu */}
                    </nav> 
                  </div> {/* //.main-menu */}
                  <div className="expand-btn-inner">
                    <a id="nav-expander" className="nav-expander style3">
                      <span className="dot1" />
                      <span className="dot2" />
                      <span className="dot3" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Menu End */}
        {/* Canvas Menu start */}
        <nav className="right_menu_togle hidden-md">
          <div className="close-btn">
            <div id="nav-close">
              <div className="line">
                <span className="line1" /><span className="line2" />
              </div>
            </div>
          </div>
          <div className="canvas-logo">
            <img src="/logo.png" alt="logo" />
          </div>

          <div className="offcanvas-gallery">
            <div className="gallery-img">
              <a className="image-popup" href="/sidebar_images/1.jpg"><img src="/sidebar_images/1.jpg" alt="" /></a>
            </div>
            <div className="gallery-img">
              <a className="image-popup" href="/sidebar_images/2.jpg"><img src="/sidebar_images/2.jpg" alt="" /></a>
            </div>
            <div className="gallery-img">
              <a className="image-popup" href="/sidebar_images/3.jpg"><img src="/sidebar_images/3.jpg" alt="" /></a>
            </div>
            <div className="gallery-img">
              <a className="image-popup" href="/sidebar_images/4.jpg"><img src="/sidebar_images/4.jpg" alt="" /></a>
            </div>
            <div className="gallery-img">
              <a className="image-popup" href="/sidebar_images/5.jpg"><img src="/sidebar_images/5.jpg" alt="" /></a>
            </div>
   
          </div>
          <div className="map-img">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11809.875310719506!2d42.7051745!3d42.2685146!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405c8cfac1dcf015%3A0x3b1deaa15ad1c7f9!2sUNIK%20-%20Kutaisi%20University!5e0!3m2!1sen!2sin!4v1721326600842!5m2!1sen!2sin"
                width={380}
                style={{ border: 0 }}
                allowFullScreen=""
               
              />
          </div>
          <div className="canvas-contact">
            <ul className="social">
              <li><a href="https://www.facebook.com/Kutaisi-University-College-of-Medicine-Kutaisi-City-Georgia-106190881743284/?ref=pages_you_manage"><i className="fa fa-facebook" /></a></li>
              <li><a href="https://twitter.com/Royalnextoff"><i className="fa fa-twitter" /></a></li>
              <li><a href="https://www.instagram.com/royalnextacademy/"><i className="fa fa-instagram" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCXEIdPJUowws2aQ58rFv72A"><i className="fa fa-youtube" /></a></li>
            </ul>
          </div>
        </nav>
        {/* Canvas Menu end */}
      </header>
		</>
	)
}