import React,{useEffect} from 'react'
import {Banner} from './sub/Banner'
import { Helmet } from 'react-helmet-async';

export const About = () => {
	useEffect(()=>{
		window.$('.rs-carousel').each(function() {
        var owlCarousel = window.$(this),
        loop = owlCarousel.data('loop'),
        items = owlCarousel.data('items'),
        margin = owlCarousel.data('margin'),
        stagePadding = owlCarousel.data('stage-padding'),
        autoplay = owlCarousel.data('autoplay'),
        autoplayTimeout = owlCarousel.data('autoplay-timeout'),
        smartSpeed = owlCarousel.data('smart-speed'),
        dots = owlCarousel.data('dots'),
        nav = owlCarousel.data('nav'),
        navSpeed = owlCarousel.data('nav-speed'),
        xsDevice = owlCarousel.data('mobile-device'),
        xsDeviceNav = owlCarousel.data('mobile-device-nav'),
        xsDeviceDots = owlCarousel.data('mobile-device-dots'),
        smDevice = owlCarousel.data('ipad-device'),
        smDeviceNav = owlCarousel.data('ipad-device-nav'),
        smDeviceDots = owlCarousel.data('ipad-device-dots'),
        smDevice2 = owlCarousel.data('ipad-device2'),
        smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
        smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
        mdDevice = owlCarousel.data('md-device'),
        centerMode = owlCarousel.data('center-mode'),
        HoverPause = owlCarousel.data('hoverpause'),
        mdDeviceNav = owlCarousel.data('md-device-nav'),
        mdDeviceDots = owlCarousel.data('md-device-dots');
        owlCarousel.owlCarousel({
            loop: (loop ? true : false),
            items: (items ? items : 4),
            lazyLoad: true,
            center: (centerMode ? true : false),
            autoplayHoverPause: (HoverPause ? true : false),
            margin: (margin ? margin : 0),
            //stagePadding: (stagePadding ? stagePadding : 0),
            autoplay: (autoplay ? true : false),
            autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
            smartSpeed: (smartSpeed ? smartSpeed : 250),
            dots: (dots ? true : false),
            nav: (nav ? true : false),
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            navSpeed: (navSpeed ? true : false),
            responsiveClass: true,
            responsive: {
                0: {
                    items: (xsDevice ? xsDevice : 1),
                    nav: (xsDeviceNav ? true : false),
                    dots: (xsDeviceDots ? true : false),
                    center: false,
                },
                576: {
                    items: (smDevice2 ? smDevice2 : 2),
                    nav: (smDeviceNav2 ? true : false),
                    dots: (smDeviceDots2 ? true : false),
                    center: false,
                },
                768: {
                    items: (smDevice ? smDevice : 3),
                    nav: (smDeviceNav ? true : false),
                    dots: (smDeviceDots ? true : false),
                    center: false,
                },
                992: {
                    items: (mdDevice ? mdDevice : 4),
                    nav: (mdDeviceNav ? true : false),
                    dots: (mdDeviceDots ? true : false),
                }
            }
        });
    });
	},[])
	return(
		<>
      <Helmet>
				<title>Get A High Quality Medical Education In Europe  At Kutaisi University</title>
				<meta name="description" content="Your dream of becoming a doctor by studying MBBS at Kutaisi University in Georgia. With a high-quality education and affordable fees, Kutaisi University is the perfect choice for aspiring medical professionals. Start your journey to becoming a doctor now." />
				<meta name="keywords" content="Kutaisi University Official Website , Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://europeanuniversity.in/about-us" />
			</Helmet>

			<div className="rs-breadcrumbs breadcrumbs-overlay">
		        <div className="breadcrumbs-img">
		          <img src="banner/page.jpg" alt="Breadcrumbs Image" />
		        </div>
		        <div className="breadcrumbs-text white-color">
		          <h1 className="page-title">About Us</h1>
		          <ul>
		            <li>
		              <a className="active" href="/">Home</a>
		            </li>
		            <li>About Us</li>
		          </ul>
		        </div>
		      </div>


		      <div id="rs-about" className="rs-about style1 pt-100 pb-100 md-pt-70 md-pb-70">
		        <div className="container">
		          <div className="row align-items-center">
		            <div className="col-lg-6 order-last padding-0 md-pl-15 md-pr-15 md-mb-30">
		              <div className="img-part">
		                <img className src="/banner/banner_1_3.jpg" alt="About Image" />
		              </div>
		            </div>
		            <div className="col-lg-6 pr-70 md-pr-15">
		              <div className="sec-title">
		                <div className="sub-title orange">About Kutaisi University</div>
		                <div className="bold-text mb-22 text-justify">Kutaisi University (UNIK) is the oldest private university in whole Georgia, though it’s relatively young Higher 
                      Education Institution of 29 years with young, professional and motivated academic and administrative staff. 
                      Being the first private university, it has become a starting point for private universities establishment throughout the country. UNIK has gained a high reputation for its continuous development, backed up by almost 
                      three decades of ongoing authorized and accredited status on national level and now crowned by the international accreditation of the Medical program.
                      We highly value and respect your individualism and help to develop it. What matters to us is who you are. At 
                      the same time, we are united by values that help us to understand each other and the surrounding world. Our 
                      goal is to create your own unique UNIK experience</div>
		                
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>

		      <Banner />


		      <div className="rs-about style1 pt-100 pb-100 md-pt-70 md-pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 padding-0 md-pl-15 md-pr-15 md-mb-30">
              <div className="img-part">
                <img className src="assets/images/history.png" alt="About Image" />
              </div>
              <ul className="nav nav-tabs histort-part" id="myTab" role="tablist">
                <li className="nav-item tab-btns single-history">
                  <a className="nav-link tab-btn active" id="about-history-tab" data-toggle="tab" href="#about-history" role="tab" aria-controls="about-history" aria-selected="true"><span className="icon-part"><i className="flaticon-chart" /></span>Strategy</a>
                </li>
                <li className="nav-item tab-btns single-history">
                  <a className="nav-link tab-btn" id="about-mission-tab" data-toggle="tab" href="#about-mission" role="tab" aria-controls="about-mission" aria-selected="false"><span className="icon-part"><i className="flaticon-flower" /></span>Mission &amp; Vission</a>
                </li>
                <li className="nav-item tab-btns single-history last-item">
                  <a className="nav-link tab-btn" id="about-admin-tab" data-toggle="tab" href="#about-admin" role="tab" aria-controls="about-admin" aria-selected="false"><span className="icon-part"><i className="flaticon-analysis" /></span>Administration</a>
                </li>
              </ul>
            </div>
            <div className="offset-lg-1" />
            <div className="col-lg-5">
              <div className="tab-content tabs-content" id="myTabContent">
                <div className="tab-pane tab fade show active" id="about-history" role="tabpanel" aria-labelledby="about-history-tab">
                  <div className="sec-title mb-25">
                    <h2 className="title">Strategy</h2>
                    <div className="desc text-justify">The present document is the strategy development plan of University of Kutaisi LLC, which defines the main aspects of development of the University as the holistic institution together with its separate structural units, according to the mission, vision and values of the University.</div>
                  </div>
                  <div className="tab-img">
                    <img className src="/sidebar_images/4.jpg" alt="Tab Image" />
                  </div>
                </div>
                <div className="tab-pane fade" id="about-mission" role="tabpanel" aria-labelledby="about-mission-tab">
                  <div className="sec-title mb-25">
                    <h2 className="title">Mission</h2>
                    <div className="desc text-justify">l  Preparation of the workforce with the conformable competence and education that would comply with the international, national and regional labour market requirements, who would contribute to development of ethical and socially responsible society as well as of the corresponding sphere and bring their own merits to the economic prosperity of our country.
l  Strengthening of the academic society on the local level. Contribution to generating the new knowledge and its sharing, taking into consideration the resources of the university and the specifics of the region.</div>
                  </div>
                  <div className="tab-img">
                    <img className src="/sidebar_images/2.jpg" alt="Tab Image" />
                  </div>
                </div>
                <div className="tab-pane fade" id="about-admin" role="tabpanel" aria-labelledby="about-admin-tab">
                  <div className="sec-title mb-25">
                    <h2 className="title">Kutaisi University Administration</h2>
                    <a className="readon2" href="https://docs.google.com/forms/d/e/1FAIpQLSfG_gEELiP7giDzf8YlTQAIYZ7ABFstddHFJypPzvHkpiUP1w/viewform" target="_blank">Applay Now</a>
                  </div>
                  <div className="tab-img">
                    <img className src="assets/images/about/tab3.jpg" alt="Tab Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Intro Info Tabs*/}
          <div className="intro-info-tabs">
          </div>
        </div>
      </div>


      <div id="rs-team" className="rs-team style1 orange-color pt-94 pb-100 md-pt-64 md-pb-70 gray-bg">
        <div className="container">
          <div className="sec-title mb-50 md-mb-30">
            <div className="sub-title orange">Students Testimonial</div>
            <h2 className="title mb-0"></h2>
          </div>
          <div className="rs-carousel owl-carousel nav-style2 orange-color" data-loop="true" data-items={3} data-margin={30} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="true" data-nav-speed="false" data-center-mode="false" data-mobile-device={1} data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device={2} data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2={2} data-ipad-device-nav2="false" data-ipad-device-dots2="false" data-md-device={3} data-md-device-nav="true" data-md-device-dots="false">
            <div className="team-item">
              <img src="testimonial/1.jpg" alt="" />
              <div className="content-part">
                <h4 className="name"><a href="">Harini</a></h4>
                <span className="designation">Student</span>
                <span className="designation">2021 - 2022</span>
              </div>
            </div>

            <div className="team-item">
              <img src="testimonial/2.jpg" alt="" />
              <div className="content-part">
                <h4 className="name"><a href="">Gayathri</a></h4>
                <span className="designation">Student</span>
                <span className="designation">2021 - 2022</span>
              </div>
            </div>

            <div className="team-item">
              <img src="testimonial/3.jpg" alt="" />
              <div className="content-part">
                <h4 className="name"><a href="">Justin Raja</a></h4>
                <span className="designation">Student</span>
                <span className="designation">2021 - 2022</span>
              </div>
            </div>

            <div className="team-item">
              <img src="testimonial/4.jpg" alt="" />
              <div className="content-part">
                <h4 className="name"><a href="">Guru Vishal</a></h4>
                <span className="designation">Student</span>
                <span className="designation">2021 - 2022</span>
              </div>
            </div>

            <div className="team-item">
              <img src="testimonial/5.jpg" alt="" />
              <div className="content-part">
                <h4 className="name"><a href="">Ebinesh</a></h4>
                <span className="designation">Student</span>
                <span className="designation">2021 - 2022</span>
              </div>
            </div>
           

          </div>
        </div>
      </div>

		</>
	)
}