import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

export const BachelorProgram = () => {

    const [contentData,setContentData] = useState(
        [
            {
                'name':'Tourism',
                'url': '/bachelor-program/tourism',
                'image': 'tour.jpg'
            },
            {
                'name':'Law',
                'url': '/bachelor-program/law',
                'image': 'law.jpg'
            },
            {
                'name':'Economics',
                'url': '/bachelor-program/economics',
                'image': 'economics.png'
            },
            {
                'name':'Business Administration',
                'url': '/bachelor-program/business-administration',
                'image': 'business.jpg'
            }
        ]
    )

    return (

        <>
            <Helmet>
				<title>Study in Georgia Bachelor Program at Kutaisi University</title>
				<meta name="description" content="Discover the beautiful country of Georgia and pursue your bachelor's degree at Kutaisi University. Immerse yourself in a diverse and dynamic learning environment with top-notch education and international opportunities." />
				<meta name="keywords" content="Kutaisi University Bachelor Program, Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://europeanuniversity.in/bachelor-program" />
			</Helmet>

            <div className="rs-breadcrumbs breadcrumbs-overlay">
                <div className="breadcrumbs-img">
                    <img src="banner/page.jpg" alt="Breadcrumbs Image" className="custom_height" />
                </div>
                <div className="breadcrumbs-text white-color">
                    <h1 className="page-title">Programs</h1>
                    <ul>
                        <li>
                            <a className="active" href="/">Home</a>
                        </li>
                        <li>Bachelor Program</li>
                    </ul>
                </div>
            </div>
            {/* Categories Section Start */}
            <div id="rs-categories" className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-40">
                <div className="container">
                    {/* <div className="sec-title3 text-center mb-45">
                        <div className="sub-title"> Top Categories</div>
                        <h2 className="title black-color">Popular Online Categories</h2>
                    </div> */}
                    <div className="row mb-35">
                        {contentData.map((item, index)=>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="categories-items">
                                    <div className="cate-images">
                                        <Link to={item?.url}>
                                            <img src={'content_images/'+item?.image} alt="" />
                                        </Link>
                                    </div>
                                    <div className="contents">
                                        <div className="img-part">
                                            <img
                                                src="assets/images/categories/main-home/icon/1.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className="content-wrap">
                                            <h2 className="title">
                                                <Link to={item?.url}>{item?.name}</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                    </div>
                    
                </div>
            </div>
            {/* Categories Section End */}
        </>

    )
}