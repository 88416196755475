import { Helmet } from 'react-helmet-async';

export const Contact = () => {

  return (
    <>
      
      <Helmet>
				<title>Kutaisi University Georgia Direct Admission Office India</title>
				<meta name="description" content="Kutaisi University Georgia. Direct Indian Admission office 32/67, East Jones Road, Saidapet.Chennai-600015, Nadu Telephone: +91 8939211180, 9962705000, Georgia: +99557932 3857, +995551105666" />
				<meta name="keywords" content="Kutaisi University Contact Number, Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students , Faculty Of Medicine" />
				<link rel="canonical" href="https://europeanuniversity.in/contact-us" />
			</Helmet>

      <div className="rs-breadcrumbs breadcrumbs-overlay">
        <div className="breadcrumbs-img">
          <img src="banner/page.jpg" alt="Breadcrumbs Image" />
        </div>
        <div className="breadcrumbs-text white-color padding">
          <h1 className="page-title white-color">Contact Us</h1>
          <ul>
            <li>
              <a className="active" href="/">Home</a>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>

      <div className="contact-page-section orange-color pt-100 pb-100 md-pt-70 md-pb-70">
        <div className="container">
          <div className="row align-items-center pb-50">
            <div className="col-lg-4 md-mb-50">
              <div className="contact-address-section style2">
                <div className="contact-info mb-15 md-mb-30">
                  <div className="icon-part">
                    <i className="fa fa-home" />
                  </div>
                  <div className="content-part">
                    <h5 className="info-subtitle">Address</h5>
                    <h4 className="info-title">
                      Indian admission office
                      <br />
                      Direct 32/67, East Jones Road, Saidapet. Chennai-600015, Tamil Nadu.
                    </h4>
                  </div>
                </div>
                <div className="contact-info mb-15 md-mb-30">
                  <div className="icon-part">
                    <i className="fa fa-envelope-open-o" />
                  </div>
                  <div className="content-part">
                    <h5 className="info-subtitle">Email Address</h5>
                    <h4 className="info-title">
                      <a href="mailto:admission@kutaisiuniversity.com">admission@kutaisiuniversity.com</a>
                    </h4>
                  </div>
                </div>
                <div className="contact-info">
                  <div className="icon-part">
                    <i className="fa fa-headphones" />
                  </div>
                  <div className="content-part">
                    <h5 className="info-subtitle">Phone Number</h5>
                    <h4 className="info-title">
                      <a href="tel:+91 9962705004">+91 9962705000</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              {/* Map Section Start */}
              <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11809.875310719506!2d42.7051745!3d42.2685146!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x405c8cfac1dcf015%3A0x3b1deaa15ad1c7f9!2sUNIK%20-%20Kutaisi%20University!5e0!3m2!1sen!2sin!4v1721326600842!5m2!1sen!2sin"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen=""
               
              />

              </div>
              {/* Map Section End */}
            </div>
          </div>
          <div className="row align-items-end contact-bg1">
            <div className="col-lg-4 md-pt-50 lg-pr-0">
              <div className="contact-image">
                <img src="assets/images/contact.png" alt="Contact Images" />
              </div>
            </div>
            <div className="col-lg-8 lg-pl-0">
              <div className="rs-quick-contact new-style">
                <div className="inner-part mb-35">
                  <h2 className="title mb-15">Get In Touch</h2>
                  
                </div>
                <div id="form-messages" />
                
                  <div className="row">
                    <div className="col-lg-6 mb-30 col-md-6 col-sm-6">
                      <input
                        className="from-control"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required=""
                      />
                    </div>
                    <div className="col-lg-6 mb-30 col-md-6 col-sm-6">
                      <input
                        className="from-control"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email"
                        required=""
                      />
                    </div>
                    <div className="col-lg-6 mb-30 col-md-6 col-sm-6">
                      <input
                        className="from-control"
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        required=""
                      />
                    </div>
                    <div className="col-lg-6 mb-30 col-md-6 col-sm-6">
                      <input
                        className="from-control"
                        type="text"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </div>
                    <div className="col-lg-12 mb-40">
                      <textarea
                        className="from-control"
                        id="message"
                        name="message"
                        placeholder=" Message"
                        required=""
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <input
                      className="btn-send"
                      type="submit"
                      defaultValue="Submit Now"
                    />
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}