import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

export const Tourism = () => {
    return (

        <>
            <Helmet>
				<title>Study in Georgia Bachelor Program at Kutaisi University - Tourism</title>
				<meta name="description" content="Discover the beautiful country of Georgia and pursue your bachelor's degree at Kutaisi University. Immerse yourself in a diverse and dynamic learning environment with top-notch education and international opportunities." />
				<meta name="keywords" content="Kutaisi University Bachelor Program, Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://europeanuniversity.in/bachelor-program/tourism" />
			</Helmet>

            <div className="rs-breadcrumbs breadcrumbs-overlay">
                <div className="breadcrumbs-img">
                    <img src="/banner/page.jpg" alt="Breadcrumbs Image" className="custom_height" />
                </div>
                <div className="breadcrumbs-text white-color">
                    <h1 className="page-title">Programs</h1>
                    <ul>
                        <li>
                            <a className="active" href="/">Home</a>
                        </li>
                        <li>
                            <a className="active" href="/">Bachelor Program</a>
                        </li>
                        <li>Tourism</li>
                    </ul>
                </div>
            </div>
            {/* Categories Section Start */}
            <div id="rs-categories" className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-40">
                <div className="container">
                    {/* <div className="sec-title3 text-center mb-45">
                        <div className="sub-title"> Top Categories</div>
                        <h2 className="title black-color">Popular Online Categories</h2>
                    </div> */}
                    <div className="row mb-35">
                        
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>Education level</td>
                                <td>Bachelor (Level I of Higher Education)</td>
                            </tr>
                            <tr>
                                <td>Study language</td>
                                <td>Georgian</td>
                            </tr>
                            <tr>
                                <td>Educational program type</td>
                                <td>Academic</td>
                            </tr>
                            <tr>
                                <td>Detailed field name and code</td>
                                <td>1015 Travel, tourism and leisure</td>
                            </tr>
                            <tr>
                                <td>Qualification awarded</td>
                                <td>Bachelor of Economics</td>
                            </tr>
                            <tr>
                                <td>Duration of study</td>
                                <td>4 years (8 semesters)</td>
                            </tr>
                            <tr>
                                <td>Amount of allocated credits</td>
                                <td>240 credits (ECTS)</td>
                            </tr>
                            <tr>
                                <td>Head of the educational program</td>
                                <td>Professor, Dr. Maia Azmaiparashvili.</td>
                            </tr>
                        </tbody>
                    </table>
                        
                    </div>
                    
                </div>
            </div>
            {/* Categories Section End */}
        </>

    )
}