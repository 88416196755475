import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

export const Finance = () => {
    return (

        <>
            <Helmet>
				<title>Study in Georgia Bachelor Program at Kutaisi University - Finances</title>
				<meta name="description" content="Experience a world-class education and advance your career with our top-ranked Master's program at Kutaisi University in Georgia. Apply now and unlock your potential for success." />
				<meta name="keywords" content="Kutaisi University Master Program, Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://europeanuniversity.in/master-program/finances" />
			</Helmet>

            <div className="rs-breadcrumbs breadcrumbs-overlay">
                <div className="breadcrumbs-img">
                    <img src="/banner/page.jpg" alt="Breadcrumbs Image" className="custom_height" />
                </div>
                <div className="breadcrumbs-text white-color">
                    <h1 className="page-title">Programs</h1>
                    <ul>
                        <li>
                            <a className="active" href="/">Home</a>
                        </li>
                        <li>
                            <a className="active" href="/">Master Program</a>
                        </li>
                        <li>Finances</li>
                    </ul>
                </div>
            </div>
            {/* Categories Section Start */}
            <div id="rs-categories" className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-40">
                <div className="container">
                    {/* <div className="sec-title3 text-center mb-45">
                        <div className="sub-title"> Top Categories</div>
                        <h2 className="title black-color">Popular Online Categories</h2>
                    </div> */}
                    <div className="row mb-35">

                        <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>Education level</td>
                                <td>Master (Level 2 of Higher Education)</td>
                            </tr>
                            <tr>
                                <td>Study language</td>
                                <td>Georgian</td>
                            </tr>
                            <tr>
                                <td>Educational program type</td>
                                <td>Academic</td>
                            </tr>
                            <tr>
                                <td>Detailed field name and code</td>
                                <td>0412 Finance, Banking and Insurance</td>
                            </tr>
                            <tr>
                                <td>Qualification awarded</td>
                                <td>MBA in Finance</td>
                            </tr>
                            <tr>
                                <td>Duration of study</td>
                                <td>2 years (4 semesters)</td>
                            </tr>
                            <tr>
                                <td>Amount of allocated credits</td>
                                <td>120 credits (ECTS)</td>
                            </tr>
                            <tr>
                                <td>Head of the educational program</td>
                                <td>Gocha Tutberidze, Doctor of Economics</td>
                            </tr>
                            <tr>
                                <td>Head of the educational program</td>
                                <td>Mirza Khidasheli, Doctor of Business Administration</td>
                            </tr>
                        </tbody>
                        </table>

                    </div>

                </div>
            </div>
            {/* Categories Section End */}
        </>

    )
}