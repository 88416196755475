import React,{useEffect} from 'react'

export const Banner = () => {
	useEffect(()=>{
	    var popupvideos = window.$('.popup-videos');
	    if(popupvideos.length){
	        window.$('.popup-videos').magnificPopup({
	            disableOn: 10,
	            type: 'iframe',
	            mainClass: 'mfp-fade',
	            removalDelay: 160,
	            preloader: false,
	            fixedContentPos: false
	        }); 
	    }
	})
	return(
		<>
			<div className="rs-cta style2">
		        <div className="partition-bg-wrap home2">
		          <div className="container">
		            <div className="row y-bottom">
		              <div className="col-lg-6 pb-50 md-pt-100 md-pb-100">
		                <div className="video-wrap">
		                  <a className="popup-videos" href="https://www.youtube.com/watch?v=AfvIaDINa1Y">
		                    <i className="fa fa-play" />
		                    <h4 className="title mb-0">Take a Video  Tour at #UNIK</h4>
		                  </a>
		                </div>
		              </div>
		              <div className="col-lg-6 pl-62 pt-134 pb-150 md-pl-15 md-pt-45 md-pb-50">
		                <div className="sec-title mb-40 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="2000ms">
		                  <h2 className="title mb-16">Admission Open for {(new Date().getFullYear())-1} - {(new Date().getFullYear())}</h2>
		                  <div className="desc" style={{fontSize:24}}>Are you ready for your next journey with us?</div>
		                </div>
		                <div className="btn-part wow fadeInUp" data-wow-delay="400ms" data-wow-duration="2000ms">
		                  <a className="readon2" href="https://docs.google.com/forms/d/e/1FAIpQLSfG_gEELiP7giDzf8YlTQAIYZ7ABFstddHFJypPzvHkpiUP1w/viewform" target="_blank">Apply Now</a>
		                </div>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>
		</>
	)
}