import React,{useEffect} from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';

//pages
import {Homepage} from './pages/Home'
import {Gallery} from './pages/Gallery'
import {Contact} from './pages/Contact'
import {About} from './pages/About'
import {Blog} from './pages/Blog'
import {Notfound} from './pages/Notfound'

//components
import {PageLoader} from './components/pageLoader'
import {Nav} from './components/Nav'
import {Footer} from './components/Footer'
import { BachelorProgram } from './pages/BacheloreProgram';
import { Tourism } from './pages/programs/Tourism';
import { Law } from './pages/programs/Law';
import { Economics } from './pages/programs/Economics';
import { Business } from './pages/programs/Business';
import { MasterProgram } from './pages/MasterProgram';
import { Finance } from './pages/programs/Finance';
import { Accounts } from './pages/programs/Accounts';
import { DoctorateProgram } from './pages/DoctorateProgram';
import { MediacalProgram } from './pages/MedicalProgram';
import { Medical } from './pages/programs/Medical';
import { Medicine } from './pages/faculty/Medicine';
import { Social } from './pages/faculty/Social';

function App() {
  useEffect(()=>{
     document.body.classList.add('home-style2');
  },[])
  return (
    <>
    <PageLoader />
    <div className="full-width-header header-style2">
      <Nav />
       
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/blog" element={<Blog />} />

          <Route path='/bachelor-program' element={<BachelorProgram />} />
          <Route path='/bachelor-program/tourism' element={<Tourism />} />
          <Route path='/bachelor-program/law' element={<Law />} />
          <Route path='/bachelor-program/economics' element={<Economics />} />
          <Route path='/bachelor-program/business-administration' element={<Business />} />
          
          <Route path='/master-program' element={<MasterProgram />} />
          <Route path='/master-program/finances' element={<Finance />} />
          <Route path='/master-program/accounting-and-auditing' element={<Accounts />} />
          
          <Route path='/doctorate-program' element={<DoctorateProgram />} />
          <Route path='/doctorate-program/economics' element={<Economics />} />
          
          <Route path='/medical-program' element={<MediacalProgram />} />
          <Route path='/medical-program/medical-doctor' element={<Medical />} />
          
          <Route path='/faculty-of-medicine' element={<Medicine />} />
          <Route path='/faculty-of-social-sciences-business-and-economics' element={<Social />} />
        </Routes>
      </div>

      <Footer />

    </div>
   
    </>
  );
}

export default App;
