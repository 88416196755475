import React,{useState,useMemo} from 'react'

export const BlogList = () => {
	const [blogs,setBlog] = useState()

	const d = new Date();

	useMemo(()=>{
		async function getBlogs(){
			fetch(`https://www.googleapis.com/blogger/v3/blogs/5334874226674590227/posts/?fetchImages=true&fetchBodies=true&key=AIzaSyDrhCIuH_HQekFFdYp_HQJNB2z9FVnnRpI`)
		      .then(res => res.json())
		      .then(
		        (result) => {
		        	setBlog(result)
		        },
		        (error) => {
		          console.log(error)
		        }
		    )
		}
	    getBlogs()
	},[])

	return(
		<>
			<div className="rs-event orange-color pt-100 pb-100 md-pt-70 md-pb-70">
		        <div className="container">
		          <div className="row">
		          	{blogs !== undefined && blogs.items !== undefined && blogs.items.length !==0 && blogs.items.map((blogsArray, index) => (
			            <div className="col-lg-4 mb-60 col-md-6">
			              <div className="event-item">
			                <div className="event-short">
			                  <div className="featured-img">
			                    <img src={blogsArray.images[0].url} alt="Image" />
			                  </div>
			                  <div className="content-part">
			                  	<div class="address"><i class="fa fa-user-o"></i> {blogsArray.author.displayName}</div>
			                    <h4 className="title"><a href={blogsArray.url} target="_blank">{blogsArray.title}</a></h4>
			                    <p className="text">
			                      {blogsArray.title}
			                    </p>
			                    <div className="event-btm">
			                      <div className="date-part">
			                        <div className="date">
			                          <i className="fa fa-calendar-check-o" />
			                          <DateTime data={blogsArray.published} />
			                        </div>
			                      </div>
			                      <div className="btn-part">
			                        <a href={blogsArray.url} target="_blank">Read More</a>
			                      </div>
			                    </div>
			                  </div> 
			                </div>
			              </div>
			            </div>
		            ))} 

		          </div>
		        </div> 
		      </div>
		</>
	)
}

export const DateTime = ({data}) => {
	const event = new Date(data);

	return event.toDateString()
}