import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

export const Social = () => {

    return (

        <>
            <Helmet>
				<title>Faculty of Social Sciences | Business and Economics Kutaisi University</title>
				<meta name="description" content="Enroll in one of the top social sciences  Business and Economics programs at Kutaisi University and experience high-quality education from teachers with decades of field experience." />
				<meta name="keywords" content="Kutaisi University Medical Program, Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students , Faculty Of Medicine" />
				<link rel="canonical" href="https://europeanuniversity.in/faculty-of-social-sciences-business-and-economics" />
			</Helmet>

            <div className="rs-breadcrumbs breadcrumbs-overlay">
                <div className="breadcrumbs-img">
                    <img src="banner/page.jpg" alt="Breadcrumbs Image" className="custom_height" />
                </div>
                <div className="breadcrumbs-text white-color">
                    <h1 className="page-title">Faculties</h1>
                    <ul>
                        <li>
                            <a className="active" href="/">Home</a>
                        </li>
                        <li>Faculty of Social Sciences, Business and Economics</li>
                    </ul>
                </div>
            </div>
            {/* Categories Section Start */}
            <div id="rs-categories" className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-40">
                <div className="container">
                    {/* <div className="sec-title3 text-center mb-45">
                        <div className="sub-title"> Top Categories</div>
                        <h2 className="title black-color">Popular Online Categories</h2>
                    </div> */}
                    <div className="row mb-35">

                        <div className="entry-content clear" itemProp="text">
                            <h2>Faculty of Social Sciences, Business and Economics</h2>
                            <p>
                                The Faculty of Social Sciences, Business, and Law is implementing the most
                                demanding study programs in all three Levels of Higher Education at Kutaisi
                                University. Our graduates have all the necessary skills and comprehensive
                                education that enhance their competitiveness in the job market.
                            </p>
                            <p>
                                The faculty offers undergraduate, postgraduate, and doctoral programs of
                                modern standards in Georgian language. Our Employees are highly qualified
                                local and foreign professors and practicing specialists.
                            </p>
                            <p>
                                The purpose of the faculty of Social Sciences, Business, and Law is to train
                                student-centered, competitive specialists who will be able to participate in
                                various sectors of business that meet state-of-the-art standards at the
                                local and international level, providing employment opportunities in the
                                public, private, and non-governmental sectors. Educational programs provide
                                students with a deep knowledge of practical and fundamental theoretical and
                                practical issues in Business and Entrepreneurship; Our programs allow
                                students to respond to the challenges of the modern business environment and
                                assist them in planning and developing their careers.
                            </p>
                            <p>
                                Faculty has a scientific journal “Economic Profile”. The faculty provides
                                students and academic staff with the opportunity to gain international
                                experience and professional development through exchange programs.
                            </p>
                            <p>90% of our graduates are employed.</p>
                            <p />
                        </div>


                    </div>

                </div>
            </div>
            {/* Categories Section End */}
        </>

    )
}