import React,{useEffect} from 'react'

export const PageLoader = () => {
	useEffect(()=>{

        window.$("#loading").delay(1500).fadeOut(500);
        window.$("#loading-center").on( 'click', function() {
        	window.$("#loading").fadeOut(500);
        })

	},[])
	return(
		<>
			<div id="loader" className="loader">
		        <div className="loader-container">
		          <div className="loader-icon">
		            <img src="/preloader.png" alt="" />
		          </div>
		        </div>
		    </div>
		</>
	)
}