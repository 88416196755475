export const classroom = [
    "_A3A0710-min.jpg",
    "_A3A0711-min.jpg",
    "_A3A0714-min.jpg",
    "_A3A0722-min.jpg",
    "_A3A0723-min.jpg",
    "_A3A0726-min.jpg",
    "_A3A0729-min.jpg",
    "_A3A0732-min.jpg",
    "_A3A0734-min.jpg",
    "_A3A0737-min.jpg",
    "_A3A0738-min.jpg",
    "_A3A0739-min.jpg",
    "_A3A0741-min.jpg",
    "_A3A0743-min.jpg",
    "_A3A0745-min.jpg",
    "_A3A0751-min.jpg",
    "_A3A0755-min.jpg",
    "_A3A0763-min.jpg",
    "_A3A0764-min.jpg",
    "_A3A0766-min.jpg",
    "_A3A0768-min.jpg",
    "_A3A0770-min.jpg",
    "_A3A0772-min.jpg",
    "_A3A0776-min.jpg",
    "_A3A0780-min.jpg",
    "_A3A0782-min.jpg",
    "_A3A0785-min.jpg",
    "_A3A0790-min.jpg",
    "_A3A0813-min.jpg",
    "_A3A0814-min.jpg",
    "_A3A0816-min.jpg",
    "_A3A0818-min.jpg",
    "_A3A0821-min.jpg",
    "_A3A0823-min.jpg",
    "_A3A0826-min.jpg",
    "_A3A0829-min.jpg",
    "_A3A0831-min.jpg",
    "_A3A0832-min.jpg",
    "_A3A0835-min.jpg",
    "_A3A0837-min.jpg",
    "_A3A0841-min.jpg",
    "_A3A0846-min.jpg",
    "_A3A0849-min.jpg",
    "_A3A0854-min.jpg",
    "_A3A0855-min.jpg",
    "_A3A0859-min.jpg",
    "1.png",
    "60b0e04a04252.jpg",
    "KU-Library.jpg",
    "Kutaisi University,Georgia-10.jpg",
    "Kutaisi University,Georgia-11.jpg",
    "Kutaisi University,Georgia-2.jpg",
    "Kutaisi University,Georgia-3.jpg",
    "Kutaisi University,Georgia-4.jpg",
    "Kutaisi University,Georgia-5.jpg",
    "Kutaisi University,Georgia-6.jpg",
    "Kutaisi University,Georgia-7.jpg",
    "Kutaisi University,Georgia-8.jpg",
    "Kutaisi University,Georgia-9.jpg",
    "Kutaisi University,Georgia.jpg",
    "SHREE DIYASAI WEBSITE kutaisi.jpg",
    "Screenshot 2022-02-07 112624.png",
    "VR-IMAGES.png",
    "VR-IMAGES2.png",
    "WhatsApp Image 2021-06-29 at 11.32.39 PM.jpeg",
    "WhatsApp Image 2021-06-29 at 11.32.51 PM.jpeg",
    "WhatsApp Image 2021-06-29 at 11.32.52 PM.jpeg",
    "WhatsApp Image 2021-06-29 at 11.34.19 PM.jpeg",
    "WhatsApp Image 2021-06-29 at 11.34.20 PM (1).jpeg",
    "WhatsApp Image 2021-06-29 at 11.34.20 PM.jpeg",
    "WhatsApp Image 2021-07-01 at 12.50.10 AM.jpeg",
    "WhatsApp Image 2021-07-01 at 12.50.19 AM.jpeg",
    "WhatsApp Image 2021-07-01 at 12.50.25 AM.jpeg",
    "WhatsApp Image 2021-07-01 at 12.50.32 AM.jpeg",
    "WhatsApp Image 2021-08-19 at 6.09.13 PM (1).jpeg",
    "WhatsApp Image 2021-08-19 at 6.09.13 PM (2).jpeg"
]

export const celebration = [
    "KU-1.jpeg",
    "KU-10.jpeg",
    "KU-11.jpeg",
    "KU-12.jpeg",
    "KU-13.jpeg",
    "KU-14.jpeg",
    "KU-15.jpeg",
    "KU-16.jpeg",
    "KU-17.jpeg",
    "KU-18.jpeg",
    "KU-19.jpeg",
    "KU-2.jpeg",
    "KU-20.jpeg",
    "KU-21.jpeg",
    "KU-22.jpeg",
    "KU-23.jpeg",
    "KU-24.jpeg",
    "KU-25.jpeg",
    "KU-26.jpeg",
    "KU-27.jpeg",
    "KU-28.jpeg",
    "KU-29.jpeg",
    "KU-3.jpeg",
    "KU-30.jpeg",
    "KU-31.jpeg",
    "KU-32.jpeg",
    "KU-33.jpeg",
    "KU-34.jpeg",
    "KU-35.jpeg",
    "KU-36.jpeg",
    "KU-4.jpeg",
    "KU-5.jpeg",
    "KU-6.jpeg",
    "KU-7.jpeg",
    "KU-8.jpeg",
    "KU-9.jpeg"
]

export const hostal = [
    "1.jpeg",
    "10.jpeg",
    "11.jpeg",
    "12.jpeg",
    "13.jpeg",
    "14.jpeg",
    "15.jpeg",
    "16.jpeg",
    "17.jpeg",
    "18.jpeg",
    "19.jpeg",
    "2.jpeg",
    "20.jpeg",
    "21.jpeg",
    "3.jpeg",
    "4.jpeg",
    "5.jpeg",
    "54.jpeg",
    "55.jpeg",
    "56.jpeg",
    "57.jpeg",
    "58.jpeg",
    "59.jpeg",
    "6.jpeg",
    "60.jpeg",
    "61.jpeg",
    "62.jpeg",
    "63.jpeg",
    "64.jpeg",
    "65.jpeg",
    "66.jpeg",
    "67.jpeg",
    "69.jpeg",
    "7.jpeg",
    "70.jpeg",
    "71.jpeg",
    "72.jpeg",
    "73.jpeg",
    "8.jpeg",
    "9.jpeg"
]