import React, {useState, useEffect} from 'react'
import {Slider} from '../components/Slider'
import {Services} from './sub/Services'
import {About} from './sub/About'
import {Programs} from './sub/Programs'
import {Banner} from './sub/Banner'
import {BlogList} from '../components/Blog'
import YouTube from 'react-youtube';
import { Helmet } from 'react-helmet-async';

const opts = {
	width: '100%',
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 0,
	},
};

export const Homepage = () => {

	const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowPopup(true);
		}, 1000); return () => clearTimeout(timer);
	}, []);

	const closePopup = () => {
		setShowPopup(false);
	};



	return(
		<>
			<Helmet>
				<title>Kutaisi University official website | Study MBBS Georgia Kutaisi</title>
				<meta name="description" content="Discover the opportunity to study MBBS in Georgia at Kutaisi University. Explore our official website for more information on our world-class medical education." />
				<meta name="keywords" content="Kutaisi University Official Website , Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://kutaisiuniversity.com/" />
			</Helmet>

			{showPopup && (
				<div className="popup-overlay">
					<div className="popup-content">
						<button className="close-btn" onClick={closePopup}>
							&times;
						</button>
						<div className='orientation-container'>
							<h1 className='orientation-h1'>
							 Orientation Program! <span>2024-2025</span></h1>
							<img src={process.env.PUBLIC_URL+'click.gif'} alt='abroad' className='best-png' loading='lazy' />
						</div>
						<div className='orientation-p'> <p>During the orientation, you will get a chance to earn financial support through our exclusive <span >scholarship exam.</span></p></div>
						<div className="video-container custom_iframe">
							<YouTube videoId={'-K1Me3YU0to'} opts={opts} />
						</div>
					</div>
				</div>
			)}

			<Slider />
			{/* <Services /> */}
			<About />
			<Programs />
			<Banner />
			{/* <div className="pt-35 md-pt-35 md-pb-70">
				<div className="container">
					<div className="sec-title text-center">
	                    <div className="sub-title primary">News Update </div>
	                    <h2 class="title mb-0">Latest News & Events</h2>
	                </div>
                </div>
				<BlogList />
			</div> */}
		</>
	)
}