import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

export const Medical = () => {
    return (

        <>
            <Helmet>
				<title>Study in Georgia Bachelor Program at Kutaisi University - Medical</title>
				<meta name="description" content="Join the highly-respected medical program at Kutaisi University in Georgia and receive top-quality education and hands-on experience in the field of medicine. Apply now for a chance to become a skilled medical professional and make a difference in the world of healthcare." />
				<meta name="keywords" content="Kutaisi University Medical Program, Kutaisi University , Study MBBS in Georgia , Study Georgia , Study MBBS in Georgia for Indian Students" />
				<link rel="canonical" href="https://europeanuniversity.in/medical-program/medical-doctor" />
			</Helmet>

            <div className="rs-breadcrumbs breadcrumbs-overlay">
                <div className="breadcrumbs-img">
                    <img src="/banner/page.jpg" alt="Breadcrumbs Image" className="custom_height" />
                </div>
                <div className="breadcrumbs-text white-color">
                    <h1 className="page-title">Programs</h1>
                    <ul>
                        <li>
                            <a className="active" href="/">Home</a>
                        </li>
                        <li>
                            <a className="active" href="/">Medical Program</a>
                        </li>
                        <li>Medical Doctor</li>
                    </ul>
                </div>
            </div>
            {/* Categories Section Start */}
            <div id="rs-categories" className="rs-categories main-home pt-90 pb-100 md-pt-60 md-pb-40">
                <div className="container">
                    {/* <div className="sec-title3 text-center mb-45">
                        <div className="sub-title"> Top Categories</div>
                        <h2 className="title black-color">Popular Online Categories</h2>
                    </div> */}
                    <div className="row mb-35">
                        
                    <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td>Education level</td>
                            <td>One-cycle educational program</td>
                        </tr>
                        <tr>
                            <td>Study language</td>
                            <td>English</td>
                        </tr>
                        <tr>
                            <td>Educational program type</td>
                            <td>Academic</td>
                        </tr>
                        <tr>
                            <td>Detailed field name and code</td>
                            <td>0912 Medicine</td>
                        </tr>
                        <tr>
                            <td>Qualification awarded</td>
                            <td>Medical Doctor (MD)</td>
                        </tr>
                        <tr>
                            <td>Duration of study</td>
                            <td>6 years (12 semesters)</td>
                        </tr>
                        <tr>
                            <td>Amount of allocated credits</td>
                            <td>360 credits (ECTS)</td>
                        </tr>
                        <tr>
                            <td>Head of the educational program</td>
                            <td>Professor – Zaza Avaliani</td>
                        </tr>
                        <tr>
                            <td>Co – Head of the educational program</td>
                            <td>Doctor – Tinatin Gognadze</td>
                        </tr>
                    </tbody>
                    </table>
                        
                    </div>
                    
                </div>
            </div>
            {/* Categories Section End */}
        </>

    )
}