export const About = () => {
	return(
		<div id="rs-about" className="rs-about style2 pt-94 pb-100 md-pt-64 md-pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 pr-65 md-pr-15 md-mb-50">
              <div className="about-intro">
                <div className="sec-title mb-40 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="2000ms">
                  <div className="sub-title primary">About Kutaisi</div>
                  <h2 className="title mb-21 white-color">Welcome to Kutaisi University</h2>
                  <div className="desc big white-color">Kutaisi University (UNIK) is the oldest private university in whole Georgia, though it’s relatively young Higher 
Education Institution of 29 years with young, professional and motivated academic and administrative staff.</div>
                </div>
                <div className="btn-part wow fadeInUp" data-wow-delay="400ms" data-wow-duration="2000ms">
                  <a className="readon2" href="/about-us">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 lg-pl-0 ml--25 md-ml-0">
              <div className="row rs-counter couter-area mb-40">
                <div className="col-md-3">
                  <div className="counter-item one">
                    <h2 className="number plus">4568</h2>
                    <h4 className="title mb-0">Students</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="counter-item two">
                    <h2 className="number">640</h2>
                    <h4 className="title mb-0">Teachers</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="counter-item three">
                    <h2 className="number">54</h2>
                    <h4 className="title mb-0">Subjects</h4>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="counter-item three">
                    <h2 className="number">269</h2>
                    <h4 className="title mb-0">Degrees</h4>
                  </div>
                </div>
              </div>
              <div className="row grid-area">
                <div className="col-md-6 sm-mb-30">
                  <div className="image-grid">
                    <img src="assets/images/1.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="image-grid">
                    <img src="assets/images/2.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
	)
}