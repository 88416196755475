import { Link } from "react-router-dom"

export const Programs = () => {
	return(
		<>
			<div className="rs-degree style1 modify gray-bg pt-100 pb-70 md-pt-70 md-pb-40">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="sec-title wow fadeInUp" data-wow-delay="300ms" data-wow-duration="2000ms">
                <div className="sub-title primary">Degree categoris</div>
                <h2 className="title mb-0">Successfully Complete A Degree at Kutaisi University</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="degree-wrap">
                <img src="assets/images/degrees/1.jpg" alt="" />
                <div className="title-part">
                  <h4 className="title">Bachelor Program</h4>
                </div>
                <div className="content-part">
                  <h4 className="title"><Link to="/bachelor-program">Bachelor Program</Link></h4>
                  <p className="desc"></p>
                  <div className="btn-part">
                    <Link to="/bachelor-program">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="degree-wrap">
                <img src="assets/images/degrees/2.jpg" alt="" />
                <div className="title-part">
                  <h4 className="title">Master Program</h4>
                </div>
                <div className="content-part">
                  <h4 className="title"><Link to="/master-program">Master Program</Link></h4>
                  <p className="desc"></p>
                  <div className="btn-part">
                    <Link to="/master-program">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="degree-wrap">
                <img src="assets/images/degrees/3.jpg" alt="" />
                <div className="title-part">
                  <h4 className="title">Doctorate Program</h4>
                </div>
                <div className="content-part">
                  <h4 className="title"><Link to="/doctorate-program">Doctorate Program</Link></h4>
                  <p className="desc"></p>
                  <div className="btn-part">
                    <Link to="/doctorate-program">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <div className="degree-wrap">
                <img src="assets/images/degrees/4.jpg" alt="" />
                <div className="title-part">
                  <h4 className="title">Medical Program</h4>
                </div>
                <div className="content-part">
                  <h4 className="title"><Link to="/medical-program">Medical Program</Link></h4>
                  <p className="desc"></p>
                  <div className="btn-part">
                  <Link to="/medical-program">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
 
          </div>
        </div>
      </div>
		</>
	)
}