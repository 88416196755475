import React, { useEffect } from 'react'
import { APPLICATION_URL } from '../utils/constant';

export const Slider = () => {

	useEffect(() => {
		window.$('.rs-carousel').each(function () {
			var owlCarousel = window.$(this),
				loop = owlCarousel.data('loop'),
				items = owlCarousel.data('items'),
				margin = owlCarousel.data('margin'),
				stagePadding = owlCarousel.data('stage-padding'),
				autoplay = owlCarousel.data('autoplay'),
				autoplayTimeout = owlCarousel.data('autoplay-timeout'),
				smartSpeed = owlCarousel.data('smart-speed'),
				dots = owlCarousel.data('dots'),
				nav = owlCarousel.data('nav'),
				navSpeed = owlCarousel.data('nav-speed'),
				xsDevice = owlCarousel.data('mobile-device'),
				xsDeviceNav = owlCarousel.data('mobile-device-nav'),
				xsDeviceDots = owlCarousel.data('mobile-device-dots'),
				smDevice = owlCarousel.data('ipad-device'),
				smDeviceNav = owlCarousel.data('ipad-device-nav'),
				smDeviceDots = owlCarousel.data('ipad-device-dots'),
				smDevice2 = owlCarousel.data('ipad-device2'),
				smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
				smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
				mdDevice = owlCarousel.data('md-device'),
				centerMode = owlCarousel.data('center-mode'),
				HoverPause = owlCarousel.data('hoverpause'),
				mdDeviceNav = owlCarousel.data('md-device-nav'),
				mdDeviceDots = owlCarousel.data('md-device-dots');
			owlCarousel.owlCarousel({
				loop: (loop ? true : false),
				items: (items ? items : 4),
				lazyLoad: true,
				center: (centerMode ? true : false),
				autoplayHoverPause: (HoverPause ? true : false),
				margin: (margin ? margin : 0),
				//stagePadding: (stagePadding ? stagePadding : 0),
				autoplay: (autoplay ? true : false),
				autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
				smartSpeed: (smartSpeed ? smartSpeed : 250),
				dots: (dots ? true : false),
				nav: (nav ? true : false),
				navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
				navSpeed: (navSpeed ? true : false),
				responsiveClass: true,
				responsive: {
					0: {
						items: (xsDevice ? xsDevice : 1),
						nav: (xsDeviceNav ? true : false),
						dots: (xsDeviceDots ? true : false),
						center: false,
					},
					576: {
						items: (smDevice2 ? smDevice2 : 2),
						nav: (smDeviceNav2 ? true : false),
						dots: (smDeviceDots2 ? true : false),
						center: false,
					},
					768: {
						items: (smDevice ? smDevice : 3),
						nav: (smDeviceNav ? true : false),
						dots: (smDeviceDots ? true : false),
						center: false,
					},
					992: {
						items: (mdDevice ? mdDevice : 4),
						nav: (mdDeviceNav ? true : false),
						dots: (mdDeviceDots ? true : false),
					}
				}
			});
		});
	}, [])

	return (
		<>
			<div className="rs-slider style1">
				<div className="rs-carousel owl-carousel" data-loop="true" data-items={1} data-margin={0} data-autoplay="true" data-hoverpause="true" data-autoplay-timeout={5000} data-smart-speed={800} data-dots="false" data-nav="false" data-nav-speed="false" data-center-mode="false" data-mobile-device={1} data-mobile-device-nav="false" data-mobile-device-dots="false" data-ipad-device={1} data-ipad-device-nav="false" data-ipad-device-dots="false" data-ipad-device2={1} data-ipad-device-nav2="true" data-ipad-device-dots2="false" data-md-device={1} data-md-device-nav="true" data-md-device-dots="false">
					
					<div className="slider-content slide3">
						<div className="container">
							{/* <div className="sl-sub-title white-color wow bounceInLeft" data-wow-delay="300ms" data-wow-duration="2000ms">World Leading University</div>
							<h1 className="sl-title white-color wow fadeInRight" data-wow-delay="600ms" data-wow-duration="2000ms">Kutaisi University</h1>
							<div className="sl-btn wow fadeInUp" data-wow-delay="900ms" data-wow-duration="2000ms">
								<a className="readon2 banner-style" href="#">Discover More</a>
							</div> */}
						</div>
					</div>

					
					<div className="slider-content slide1">
						<div className="container">
							<div className="sl-sub-title white-color wow bounceInLeft" data-wow-delay="300ms" data-wow-duration="2000ms">Successfully Complete A Degree at Kutaisi University</div>
							
							<div className="sl-btn wow fadeInUp" data-wow-delay="900ms" data-wow-duration="2000ms">
								<a className="readon2 banner-style" href={APPLICATION_URL}>Apply Now</a>
							</div>
						</div>
					</div>
					<div className="slider-content slide2">
						<div className="container">
							<div className="sl-sub-title white-color wow bounceInLeft" data-wow-delay="300ms" data-wow-duration="2000ms">Medical Doctor program in kutaisiuniversity</div>
							
							<div className="sl-btn wow fadeInUp" data-wow-delay="900ms" data-wow-duration="2000ms">
								<a className="readon2 banner-style" href={APPLICATION_URL}>Discover More</a>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}