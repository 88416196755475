import { Link } from "react-router-dom"

export const Footer = () => {
	return(
		<>
	      <footer id="rs-footer" className="rs-footer">
	        <div className="footer-top">
	          <div className="container">
	            <div className="row">
	              <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
	                <h4 className="widget-title">Page</h4>
	                <ul className="site-map">
	                  <li><Link to="/">Home</Link></li>
	                  <li><Link to="/about-us">About us</Link></li>
	                  <li><Link to="/galley">Gallery</Link></li>
	                  <li><Link to="/contact-us">Contact us</Link></li>
	                </ul>
	              </div>
	              <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
	                <h4 className="widget-title">Programs</h4>
	                <ul className="site-map">
	                  <li><Link to="/bachelor-program">Bachelor Program</Link></li>
	                  <li><Link to="/master-program">Master Program</Link></li>
	                  <li><Link to="/doctorate-program">Doctorate Program</Link></li>
	                  <li><Link to="/medical-program">Medical Program</Link></li>
	                </ul>
	              </div>
	              <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
	                <h4 className="widget-title">Courses</h4>
	                <ul className="site-map">
	                  <li><Link to="/bachelor-program/law">Law</Link></li>
	                  <li><Link to="/medical-program/medical-doctor">Medical Doctor</Link></li>
	                  <li><Link to="/bachelor-program/economics">Economic</Link></li>
	                  <li><Link to="/master-program/accounting-and-auditing">Accounting and Auditing</Link></li>
	                  <li><Link to="/master-program/finances">Finances</Link></li>
	                  <li><Link to="/bachelor-program/business-administration">Business Administration</Link></li>
	                  <li><Link to="/bachelor-program/tourism">Tourism</Link></li>
	                  <li><Link to="/bachelor-program/economics">Economics</Link></li>
	                </ul>
	              </div>
	              <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
	                <h4 className="widget-title">Address</h4>
	                <ul className="address-widget">
	                  <li>
	                    <i className="flaticon-location" />
	                    <div className="desc">Direct indian admission office 32/67, East Jones Road, Saidapet. Chennai-600015, Tamil Nadu.</div>
	                  </li>
					  <li>
	                    <i className="flaticon-location" />
	                    <div className="desc">International office No 810, B Block, Hili tower, Al nadha, Sharjah, UAE.</div>
	                  </li>
	                  <li>
	                    <i className="flaticon-call" />
	                    <div className="desc">
	                      <a href="tel:+91 8939211180">+91 8939211180</a>
	                    </div>
	                  </li>
		
					  <li>
	                    <i className="flaticon-call" />
	                    <div className="desc">
	                      <a href="tel:+91 9962705004">+91 9962705000</a>
	                    </div>
	                  </li>
	                  <li>
	                    <i className="flaticon-email" />
	                    <div className="desc">
	                      <a href="mailto:admission@kutaisiuniversity.com">admission@kutaisiuniversity.com</a>
	                    </div>
	                  </li>
	                </ul>
	              </div>
	            </div>
	          </div>
	        </div>
	        <div className="footer-bottom">
	          <div className="container">                    
	            <div className="row y-middle">
	              <div className="col-lg-4 md-mb-20">
	                <div className="footer-logo md-text-center">
	                  <a href="/"><img src="w-logo.png" alt="" /></a>
	                </div>
	              </div>
	              <div className="col-lg-4 md-mb-20">
	                <div className="copyright text-center md-text-left">
	                  <p>© {(new Date().getFullYear())} All Rights Reserved. Developed By <a href="/">Kutaisi University</a></p>
	                </div>
	              </div>
	              <div className="col-lg-4 text-right md-text-left">
	                <ul className="footer-social">
	                  <li><a href="https://www.facebook.com/Kutaisi-University-College-of-Medicine-Kutaisi-City-Georgia-106190881743284/?ref=pages_you_manage"><i className="fa fa-facebook" /></a></li>
	                  <li><a href="https://twitter.com/Royalnextoff"><i className="fa fa-twitter" /></a></li>
	                  <li><a href="https://www.youtube.com/channel/UCXEIdPJUowws2aQ58rFv72A"><i className="fa fa-youtube" /></a></li>
	                  <li><a href="https://www.instagram.com/royalnextacademy/"><i className="fa fa-instagram" /></a></li>
	                </ul>
	              </div>
	            </div>
	          </div>
	        </div>
	      </footer>
		</>
	)
}